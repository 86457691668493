export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const RESET_CART = 'RESET_CART';

export const addToCart = (product, quantity, orderUUID) => {
  return {
    type: ADD_TO_CART,
    payload: {
      product,
      quantity,
      orderUUID // Pass UUID as payload
    }
  };
};


export const updateQuantity = (productId, quantity) => {
  return {
    type: UPDATE_QUANTITY,
    payload: {
      productId,
      quantity
    }
  };
};

export const deleteFromCart = (productId) => {
  return {
    type: DELETE_FROM_CART,
    payload: {
      productId
    }
  };
};

export const resetCart = () => {
  return {
    type: RESET_CART
  };
};
