import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';

const formatMarkdown = (text) => {
  return text
    .split('\n') // Split into lines
    .map(line => line.trim()) // Trim leading and trailing spaces
    .join('\n'); // Join the lines back with a newline
};

const MarkdownRenderer = ({ markdownText }) => {
  const formattedText = formatMarkdown(markdownText);

  return (
    <ReactMarkdown
      children={formattedText}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
              style={okaidia}
              language={match[1]}
              PreTag="div"
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    />
  );
};

export default MarkdownRenderer;
