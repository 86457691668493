export const getCategoryDisplayName = (category) => {
  if (!category) return "Unknown"; // Fallback for undefined or empty input

  // Split the category name by hyphens
  const words = category.split('-');

  // Capitalize the first letter of each word and join them with spaces
  const displayName = words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return displayName;
};
