import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'; // Import connect to access Redux state and actions
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Figure, FigureCaption } from 'react-bootstrap';
import countries from '../../data/countries.json';
import axios from 'axios';
import { resetCart } from '../../actions/actions'; // Action to reset the cart

import "./Checkout.css";

function Checkout({ cartItems, orderUUID, resetCart }) {
  const navigate = useNavigate();
  const { orderUUID: urlOrderUUID } = useParams();

  // Use orderUUID from Redux state or from URL params (fallback)
  const currentOrderUUID = orderUUID || urlOrderUUID;

  // Redirect to cart if no orderUUID is found
  useEffect(() => {
    if (!currentOrderUUID) {
      alert('No orderUUID found. Redirecting...');
      navigate('/'); // Redirect to home or cart page
    }
  }, [currentOrderUUID, navigate]);

  // Calculate the total amount from the cart items
  const calculateTotalAmount = () => {
    if (cartItems && cartItems.length > 0) {
      return cartItems.reduce((total, item) => {
        return total + item.product.price * item.quantity; // Calculate price * quantity for each item
      }, 0);
    }
    return 0; // Return 0 if no items in the cart
  };

  const totalAmount = calculateTotalAmount(); // Total amount in your currency (Euros, for example)

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    province: '',
    zip: '',
    country: 'Greece', // Default country
    shippingOption: 'ELTA', // Default shipping option for Greece
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'country') {
      const newShippingOption = value === 'Greece' ? 'ELTA' : 'FedEx';
      setFormData({
        ...formData,
        [name]: value,
        shippingOption: newShippingOption,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeliveryOptionChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      shippingOption: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ['firstName', 'lastName', 'email', 'country', 'shippingOption'];
    if (formData.shippingOption !== 'Box Now') {
      requiredFields.push('addressLine1', 'city', 'province', 'zip');
    }

    const isAllFieldsFilled = requiredFields.every(field => formData[field]);

    if (!isAllFieldsFilled) {
      alert('Please fill in all required fields.');
      return;
    }

    const finalData = {
      ...formData,
      orderId: currentOrderUUID, // Add orderUUID from Redux
      totalAmount: totalAmount, // Add totalAmount from calculated cart total
    };

    try {
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/createNewOrder`,
        finalData
      );
    
      // Extract the nested orderCode value
      const { orderCode } = orderResponse.data; // orderId might still be useful
      const vivaOrderCode = orderCode?.vivaOrder?.orderCode; // Safely access nested properties
      
      if (vivaOrderCode) {
        console.log("Extracted Order Code:", vivaOrderCode);

        // Resets Cart after successful transaction
        resetCart();
    
        // Construct the redirect URL with the extracted order code
        const redirectUrl = `${process.env.REACT_APP_VIVA_BASE_URL}/web/checkout?ref=${vivaOrderCode}&color=${process.env.REACT_APP_VIVA_COLOR}&paymentMethod=${process.env.REACT_APP_DEFAULT_PAYMENT_METHOD}`;
    
        // Redirect to the external page
        window.location.replace(redirectUrl);
      } else {
        alert('Order created but no valid payment order code received.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit the order. Please try again.');
    }
    
    
    
  };

  return (
    <Container className='checkout-cont'>
      <h1 className='checkout-title'>Checkout</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
        </Form.Group>

        {/* Conditionally hide address fields if Box Now is selected */}
        {formData.shippingOption !== 'Box Now' && (
          <>
            <Form.Group controlId="addressLine1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control type="text" name="addressLine1" value={formData.addressLine1} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="addressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control type="text" name="addressLine2" value={formData.addressLine2} onChange={handleChange} />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" name="city" value={formData.city} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="province">
                  <Form.Label>Province</Form.Label>
                  <Form.Control type="text" name="province" value={formData.province} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="zip">
                  <Form.Label>ZIP Code</Form.Label>
                  <Form.Control type="text" name="zip" value={formData.zip} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}

        <Form.Group controlId="country">
          <Form.Label>Country</Form.Label>
          <Form.Control as="select" name="country" value={formData.country} onChange={handleChange} required>
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Conditional Delivery Options */}
        <Form.Group>
          <Form.Label>Delivery Options</Form.Label>
          <Row>
            {formData.country === 'Greece' ? (
              <>
                <Col>
                  <Form.Check
                    type="radio"
                    name="shippingOption"
                    id="acs"
                    label={
                      <Figure>
                        <Figure.Image width={171} height={180} alt="ELTA" src="/logos/elta-logo.png" />
                        <FigureCaption>Delivery Cost: 3.5€</FigureCaption>
                      </Figure>
                    }
                    value="ELTA"
                    checked={formData.shippingOption === 'ELTA'}
                    onChange={handleDeliveryOptionChange}
                    required
                  />
                </Col>
              </>
            ) : (
              <Col>
                <Form.Check
                  type="radio"
                  name="shippingOption"
                  id="fedex"
                  label={
                    <Figure>
                      <Figure.Image width={171} height={180} alt="FedEx" src="/logos/fedex-logo.png" />
                      <FigureCaption>Delivery Cost: 3.5€</FigureCaption>
                    </Figure>
                  }
                  value="FedEx"
                  checked={formData.shippingOption === 'FedEx'}
                  onChange={handleDeliveryOptionChange}
                  required
                />
              </Col>
            )}
          </Row>
        </Form.Group>

        <Button className='order-now-button' size='lg' type="submit">
          Order Now
        </Button>
      </Form>
    </Container>
  );
}

// mapStateToProps function to get the required data from Redux store
const mapStateToProps = (state) => ({
  cartItems: state.items.cartItems,
  orderUUID: state.items.orderUUID,
});

// mapDispatchToProps function to bind the resetCart action to props
const mapDispatchToProps = {
  resetCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
