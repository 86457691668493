import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ItemCard from './ItemCard';
import Breadcrumbs from './Breadcrumbs';
import axios from 'axios';
import './ItemGrid.css';

const calculateCols = (screenWidth) => {
  if (screenWidth >= 1200) {
    return 3; // Large screens (lg)
  } else if (screenWidth >= 992) {
    return 3; // Medium screens (md)
  } else if (screenWidth >= 768) {
    return 2; // Small screens (sm)
  } else {
    return 1; // Extra small screens (xs)
  }
};

function ItemGrid({ category }) {
  const [data, setData] = useState([]);
  const [validCategories, setValidCategories] = useState([]);
  const screenWidth = window.innerWidth;
  const cols = calculateCols(screenWidth);

  useEffect(() => {
    const fetchCategoriesAndData = async () => {
      try {
        // Fetch all categories from the server
        const categoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/categories/get/all`);
        const fetchedCategories = categoryResponse.data; // Assuming the API returns a list of category names
        setValidCategories(fetchedCategories);
        console.log('Valid Categories:', fetchedCategories);
  
        // Allow "highlight" as a special category
        const isValidCategory = fetchedCategories.includes(category) || category === "highlight";
  
        if (!isValidCategory) {
          console.error('Invalid category passed:', category);
          return; // Exit if the category is invalid
        }
  
        // Determine the appropriate URL for the data request
        let url;
        if (category === "highlight") {
          url = `${process.env.REACT_APP_API_URL}/items/get/highlighted`;
        } else {
          url = `${process.env.REACT_APP_API_URL}/items/category/${category}`;
        }
  
        // Fetch items for the current category
        const response = await axios.get(url);
        console.log('API Response:', response.data);
        setData(response.data.items || response.data || []); // Adjust based on response structure
      } catch (error) {
        console.error('Error fetching categories or data:', error);
      }
    };
  
    if (category) {
      fetchCategoriesAndData();
    }
  }, [category]);
  

  return (
    <Container className='grid-cont'>
      <Breadcrumbs type="category" data={data} />
      {data.length > 0 ? (
        <Row>
          {data.map((item) => (
            <Col className='item-grid-col' key={item.id} xs={12} sm={12 / cols} md={12 / cols} lg={12 / cols}>
              <ItemCard item={item} />
            </Col>
          ))}
        </Row>
      ) : (
        <div>No items available for this category.</div>
      )}
    </Container>
  );
}

export default ItemGrid;
