import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import './Confirmation.css';

const ConfirmationPage = () => {
  const [searchParams] = useSearchParams();

  // Extract query parameters
  const eventId = searchParams.get('EventId');
  const transactionId = searchParams.get('t');
  const paymentOrderCode = searchParams.get('s');
  const language = searchParams.get('lang');

  return (
    <Container fluid className="confirmation-container">
      <Row>
        <Col>
          <h1 className="confirmation-heading">Thank you for your order!</h1>
          <p className="confirmation-text">Your order has been successfully placed.</p>
          <h2 className="confirmation-text">Event ID: {eventId}</h2>
          <h3 className="confirmation-text">Transaction ID: {transactionId}</h3>
          <p className="confirmation-text">Payment Order Code: {paymentOrderCode}</p>
          <p className="confirmation-text">Language: {language}</p>
          <p className="confirmation-text">
            SVK Robotics have been informed of the order placed and an email will be sent to you with payment details.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmationPage;
