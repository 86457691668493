// src/components/FeatureGrid.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './FeatureGrid.css'; // Import the CSS for styling

function FeatureGrid() {
  return (
    <div className="feature-grid">
      <Container>
        {/* First Row */}
        <Row className="grid-row">
          <Col md={6} className="grid-item image-cell">
            <img src="/photos/Frontpage/projects-Photoroom.jpg" alt="Robotic Lab" className="grid-image" />
          </Col>
          <Col md={6} className="grid-item text-cell bg-colored">
            <div className="text-content">
              <h2>Robotic / Mechatronic Solutions</h2>
              <p>Our company specializes in cutting-edge robotic and mechatronic solutions designed to revolutionize industries. We provide customizable, high-performance robotics to meet diverse needs across manufacturing, healthcare, and more.</p>
            </div>
          </Col>
        </Row>

        {/* Second Row */}
        <Row className="grid-row">
          <Col md={6} className="grid-item text-cell bg-colored">
            <div className="text-content">
              <h2>Educational Robotic Kits</h2>
              <p>We offer a range of educational robotic kits that inspire learning in science, technology, engineering, and math (STEM). Our kits are perfect for all ages, from beginners to advanced learners.</p>
            </div>
          </Col>
          <Col md={6} className="grid-item image-cell">
            <img src="/photos/Frontpage/svk-frontpage-edu.jpeg" alt="Arduino Uno Board" className="grid-image" />
          </Col>
        </Row>

        {/* Third Row */}
        <Row className="grid-row">
          <Col md={6} className="grid-item image-cell">
            <img src="/photos/Frontpage/pcb_site-Photoroom.jpg" alt="" className="grid-image" />
          </Col>
          <Col md={6} className="grid-item text-cell bg-colored">
            <div className="text-content">
              <h2>PCB Design/Assembly</h2>
              <p>Our expert team offers PCB design and assembly services, ensuring precise, reliable circuit boards for various applications. We handle everything from design to assembly.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FeatureGrid;
