import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import './DeclinedPage.css';

const DeclinedPage = () => {
  const [searchParams] = useSearchParams();

  // Extract query parameters
  const eventId = searchParams.get('EventId');
  const transactionId = searchParams.get('t');
  const paymentOrderCode = searchParams.get('s');
  const language = searchParams.get('lang');

  return (
    <Container fluid className="declined-container">
      <Row>
        <Col>
          <h1 className="declined-heading">Transaction Failed</h1>
          <p className="declined-text">We encountered an issue while processing your payment.</p>
          <h2 className="declined-text">Event ID: {eventId}</h2>
          <h3 className="declined-text">Transaction ID: {transactionId}</h3>
          <p className="declined-text">Payment Order Code: {paymentOrderCode}</p>
          <p className="declined-text">Language: {language}</p>
          <p className="declined-text">
            Please try again or contact our support team for assistance.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default DeclinedPage;
