// src/pages/FrontPageRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/frontpage/Home';
import PrivacyPolicy from '../pages/frontpage/PrivacyPolicy';
import TermsOfUse from '../pages/frontpage/TermsOfUse';
import About from '../pages/frontpage/About';
import Contact from '../pages/frontpage/Contact';
import Services from '../pages/frontpage/Services';
import Projects from '../pages/frontpage/Projects';


function FrontPageRoutes() {
  return (

    <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-use' element={<TermsOfUse />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/services' element={<Services />} />
        <Route path='/*' element={<Home />} />
    </Routes>

  );
}

export default FrontPageRoutes;
