import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FrontPageLayout from './layouts/FrontPageLayout';
import EshopLayout from './layouts/EshopLayout';

import EshopRoutes from './routes/EshopRoutes';
import FrontPageRoutes from './routes/FrontPageRoutes';

import ScrollToTop from '../src/utils/scrollToTop';


// bootstrap css file
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      
      <ScrollToTop />

      <Routes>
        {/* Frontpage route with FrontPageLayout */}
        <Route
          path="/"
          element={
            <FrontPageLayout>
              < FrontPageRoutes/>
            </FrontPageLayout>
          }
        />

        {/* Eshop route with EshopLayout */}
        <Route
          path="/eshop/*"
          element={
            <EshopLayout>
              <EshopRoutes />
            </EshopLayout>
          }
        />

        {/* Catch-all route that doesnt exist */}
        {/* TODO add 404 page soon TM */}
        <Route
          path="/*"
          element={
            <FrontPageLayout>
              < FrontPageRoutes/>
            </FrontPageLayout>
          }
        />
        
        {/* You can add more routes for other sections if needed */}
      </Routes>
    </Router>
  );
}

export default App;
