import React from 'react';
import Image from 'react-bootstrap/Image';
import { Table } from 'react-bootstrap';

import "./ShoppingCartTable.css";

function ShoppingCartTable ({ items }) {
  // Calculate total price
  const totalPrice = items.reduce((total, item) => {
    return total + (item.quantity * item.product.price);
  }, 0);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th className="wider-column">Product</th>
          <th>Quantity</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td className="wider-column">
              <Image className='cart-item-thumbnail' thumbnail src={item.product.images[0]} />
              {item.product.name}
            </td>
            <td className='table-quantity'>{item.quantity}</td>
            <td className='table-price'>€{(item.quantity * item.product.price).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="2">Total Amount</td>
          <td>${totalPrice.toFixed(2)}</td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default ShoppingCartTable;
