import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../actions/actions";
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import ItemImageCarousel from "../../components/eshop/ItemImageCarousel";
import QuantityControl from "../../components/eshop/QuantityControl";
import Breadcrumbs from "../../components/eshop/Breadcrumbs";
import AddToCartModal from "../../components/eshop/AddToCartModal";
import MarkdownRenderer from "../../components/eshop/MarkdownRenderer";
import { getCategoryDisplayName } from "../../utils/eshop/categoryNameUtil";

import "./Item.css";

function Item({ addToCart }) {
    const { slug } = useParams();  // Get both itemId and slug from the URL
    const [itemData, setItemData] = useState(undefined);
    const [groupItems, setGroupItems] = useState([]);
    const [selectedGroupItem, setSelectedGroupItem] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchItemData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/${slug}`);
                const item = response.data.item;

                setItemData(item);
                document.title = item.name;

                // Set the selected item as the current one by default
                setSelectedGroupItem(item);

                // Fetch group items if group_id exists
                if (item.group_id && response.data.group) {
                    setGroupItems(response.data.group);
                }

                // If the slug in the URL doesn't match the slug from the database, update the URL
                if (slug !== item.slug) {
                    navigate(`/eshop/item/${item.slug}`, { replace: true });
                }
            } catch (error) {
                console.error('Error fetching item data:', error);
            }
        };

        fetchItemData();
    }, [slug, navigate]);

    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    const handleAddToCart = () => {
        const orderUUID = uuidv4();
        addToCart(selectedGroupItem, quantity, orderUUID);
        setShowAlert(true); // Show alert when item is added to cart
    };

    const handleGroupItemChange = (selectedSlug) => {
        // Find the selected group item and set it as the selected one
        const selectedItem = groupItems.find(item => item.slug === selectedSlug);
        if (selectedItem) {
            setSelectedGroupItem(selectedItem);
            navigate(`/eshop/item/${selectedSlug}`, { replace: true });
        }
    };

    if (itemData === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <div className="item-page">

            {/* Added to cart ALERT container */}
            <Container>
                <Col>
                    {/* Render the alert component */}
                    <AddToCartModal showAlert={showAlert} setShowAlert={setShowAlert} />
                </Col>
            </Container>

            {/* Container for image carousel and add to cart buttons and other buttons */}
            <Row className="item-sale-cont">
                <Breadcrumbs type="item" data={itemData} />
                <Col>
                    <Container>
                        <ItemImageCarousel data={itemData} />
                    </Container>
                </Col>

                <Col>
                    <Container>
                        <Col>
                            <h1>{itemData?.name}</h1>
                            <hr />
                            <p>{itemData?.description_short}</p>
                            <p className="item-categories">Category: <a href={`/eshop/category/${itemData.category_name}`}>{getCategoryDisplayName(itemData.category_name)}</a></p>

                                {/* Price Display */}
                            {itemData?.price && (
                                <p className="item-price">
                                    <strong>{itemData?.price} €</strong>
                                </p>
                            )}

                            {/* Render buttons for group items if group_id exists */}
                            <Form>
                                <h4>Select a variation:</h4>
                                <div className="group-buttons-container">
                                    {groupItems.map((groupItem) => (
                                        <Button
                                            key={groupItem.id}
                                            variant="light"
                                            className={`group-item-button ${
                                                selectedGroupItem?.slug === groupItem.slug ? "selected" : ""
                                            }`}
                                            onClick={() => handleGroupItemChange(groupItem.slug)}
                                        >
                                            {groupItem.name}
                                        </Button>
                                    ))}
                                </div>
                            </Form>



                            <Row className="buy-row" md={2}>
                                <Col>
                                    <QuantityControl onQuantityChange={handleQuantityChange} />
                                </Col>
                                <Col className="buy-button-col">
                                    <Button size="lg" className="buy-button" onClick={handleAddToCart}>
                                        Add to Cart
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </Col>
            </Row>

            {/* Description Full Container, renders if description is not null */}
            {itemData?.description_full && (
            <Container className="desc-cont">
                <MarkdownRenderer markdownText={itemData?.description_full} />
            </Container>
            )}

            {/* Specifications Container, renders if specifications are not null */}
            {itemData?.specifications && (
            <div className="specs-cont">
                <Container>
                    <MarkdownRenderer markdownText={itemData?.specifications} />
                </Container>
            </div>
            )}
            
        </div>
    );
}

export default connect(null, { addToCart })(Item);
