import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the CSS file for styling

function Footer() {
  return (
    <footer className="footer bg-dark text-white">
      <Container>

        {/* Top part with logo, social icons, and Google Maps */}
        <div className="footer-top py-4">
          <Row>
            {/* Company Logo */}
            <Col md={4} className="d-flex justify-content-center align-items-center">
              <Link to="/">
                <img src="/logos/LOGO SVK_WHITE.png" alt="SVK Robotics Logo" className="footer-logo-frontpage" />
              </Link>           
            </Col>

            {/* Follow Us Section with clickable social media icons */}
            <Col md={4} className="d-flex flex-column align-items-center">
              <h5>Follow Us Here</h5>
              <div className="social-icons">
                <a href="https://www.facebook.com/profile.php?id=61552752842212" target="_blank" rel="noreferrer">
                  <img src="/icons/facebook.svg" alt="Facebook" className="social-icon" />
                </a>
                <a href="https://www.instagram.com/svk.robotics/" target="_blank" rel="noreferrer">
                  <img src="/icons/instagram.svg" alt="Instagram" className="social-icon" />
                </a>
                <a href="https://gr.linkedin.com/company/svk-robotics" target="_blank" rel="noreferrer">
                  <img src="/icons/linkedin.svg" alt="LinkedIn" className="social-icon" />
                </a>
                <a href="https://github.com/SVKROBOTICS" target="_blank" rel="noreferrer">
                  <img src="/icons/github.svg" alt="Github" className="social-icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCCMymTw3tMX5TtMmOzgZi0g?app=desktop" target="_blank" rel="noreferrer">
                  <img src="/icons/youtube.svg" alt="Github" className="social-icon" />
                </a>
              </div>
            </Col>

            {/* Google Maps Widget */}
            <Col md={4} className="d-flex justify-content-center align-items-center">
              <iframe
                title="SVK Robotics Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.74129809221!2d25.126719076505765!3d35.337245172700165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x149a5b0ce30e43e3%3A0x20b36646c92ba26b!2sSVK%20ROBOTICS!5e0!3m2!1sen!2sgr!4v1728863221029!5m2!1sen!2sgr"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Col>
          </Row>
        </div>

        {/* Horizontal line */}
        <hr className="footer-divider" />

        {/* Bottom part with contact info, privacy policy, and location */}
        <div className="footer-bottom py-4">
          <Row>
            {/* Contact Us Section */}
            <Col className='contact-us' md={4}>
              <h5>Contact Us</h5>
              <p>Email: <a href='mailto:info@svkrobotics.com'>info@svkrobotics.com</a></p>
              <p>Phone: +30 281 600 8699</p>
              <p>Or: +30 694 758 6765</p>
            </Col>

            {/* Terms of Use / Privacy Policy */}
            <Col md={4} className="text-center">
              <a href="/terms-of-use" className="btn btn-link text-white">Terms of Use</a>
              <a href="/privacy-policy" className="btn btn-link text-white">Privacy Policy</a>
              <a href="/about" className="btn btn-link text-white">About us</a>
              <p className='gemh'>GEMH: 179257627000</p>
            </Col>

            {/* Location Section */}
            <Col md={4}>
              <h5>Our Location</h5>
              <p>Greece</p>
              <p>Heraklion, Crete</p>
              <p>Radamanthous 18, 71201</p>
            </Col>
          </Row>
        </div>

        {/* Horizontal line before copyright */}
        <hr className="footer-divider" />

        {/* Copyright Notice */}
        <div className="text-center copyright py-3">
          <small>©2024 SVK Robotics. All rights reserved.</small>
        </div>

      </Container>
    </footer>
  );
}

export default Footer;
