import React from "react";
import MainCarousel from "../../components/eshop/MainCarousel"
import ItemGrid from '../../components/eshop/ItemGrid';

import './Landing.css';



function Landing() {

    return(
        <>
            <div className="spacer"/>
            <h1 className="info-title">Unleash Innovation with SVK Robotics</h1>
            <MainCarousel />
            <div className="landing-info">
                <p className="info-paragraph">
                    Welcome to SVK Robotics, your destination for cutting-edge robotic solutions. Dive into a realm of educational excellence and competitive prowess with our curated collection of robots, kits, and parts. Whether you're a teen competing, an educator inspiring, or a hobbyist exploring, SVK Robotics is your partner in innovation. Explore, create, and redefine possibilities with us.                </p>
                <p className="info-paragraph">
                    SVK Robotics - Igniting Minds, Powering Progress.
                </p>
            </div>

            <div className="frontpage-grid">
                <h1>Popular Items</h1>
                <ItemGrid category="highlight"/>
            </div>
        </>
    )
}


export default Landing;