// reducers.js
import { ADD_TO_CART, UPDATE_QUANTITY, DELETE_FROM_CART, RESET_CART } from '../actions/actions';

const initialState = {
  cartItems: [],
  orderUUID: null // Initialize UUID in state
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const existingItemIndex = state.cartItems.findIndex(item => item.product.id === action.payload.product.id);

      if (existingItemIndex !== -1) {
        // If item already exists in cart, update its quantity
        const updatedCartItems = state.cartItems.map((item, index) => {
          if (index === existingItemIndex) {
            return {
              ...item,
              quantity: item.quantity + action.payload.quantity
            };
          }
          return item;
        });

        return {
          ...state,
          cartItems: updatedCartItems
        };
      } else {
        // If item does not exist in cart, add it as a new item
        const newItem = {
          product: action.payload.product,
          quantity: action.payload.quantity
        };
        return {
          ...state,
          cartItems: [...state.cartItems, newItem],
          orderUUID: state.orderUUID || action.payload.orderUUID // Store orderUUID in state only if it's not already set
        };
      }


    case UPDATE_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map(item =>
          item.product.id === action.payload.productId
            ? { ...item, quantity: action.payload.quantity }
            : item
        )
      };
    case DELETE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(item => item.product.id !== action.payload.productId)
      };
      case RESET_CART:
        return {
          ...state,
          cartItems: [],
          orderUUID: null // Reset the UUID
        };
    default:
      return state;
  }
};

export default cartReducer;
