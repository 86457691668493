// src/components/Header.js
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css'; // Import the CSS file

const eshopUrl = process.env.REACT_APP_ESHOP_URL;

function Header() {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={"/logos/logo SVK.png"}
            className="d-inline-block align-top"
            alt="SVK Robotics logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="header-nav ml-auto">
            <Nav.Link as={Link} to="/services">Services</Nav.Link>
            {/* Update for Eshop link */}
            <Nav.Link 
              href={eshopUrl} // Direct URL for the eShop
              target="_blank" // Opens in a new tab
              rel="noopener noreferrer" // Prevents referrer leakage and protects against tabnabbing
            >
              Eshop
            </Nav.Link>
            <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
