import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './Projects.css'; // Import your CSS file for styling

function Projects() {
  return (
    <Container fluid className="projects-page">
      <h1 className="projects-header">SVK Robotics Projects</h1>

      <div className="project-section">
        <Row className="align-items-center">
          <Col md={6} className="project-image-col">
            <img
              src={`${process.env.PUBLIC_URL}/photos/Frontpage/ambusqueeze.jpg`}
              alt="Ambu Squeeze"
              className="project-image"
            />
          </Col>
          <Col md={6} className="project-text-col">
            <h2 className="project-title">Ambu Squeeze</h2>
            <p className="project-description">
              The aim of the project is the development of a device for the automated compression, through a servo system, of the ventilation bag (ambu bag) used to administer oxygen to people in need of emergency respiratory support. The device replaces the manual effort of the nurse or rescuer with an automated, reliable, and easy-to-use system. The operator can adjust the number of breaths per minute offered to the patient using a suitable selector.
            </p>
          </Col>
        </Row>
      </div>

      <hr className="project-separator" />

      <div className="project-section">
        <Row className="align-items-center">
          <Col md={6} className="project-image-col">
            <img
              src={`${process.env.PUBLIC_URL}/photos/Frontpage/biomechcrete.png`}
              alt="Biomech Crete"
              className="project-image"
            />
          </Col>
          <Col md={6} className="project-text-col">
            <h2 className="project-title">BioMechCrete Team Project</h2>
            <p className="project-description">
              The Med Venti project was a student effort to create a fleet of low-cost medical mechanical ventilators that could be manufactured directly in large quantities. This effort was made due to the possibility of a possible shortage of ventilators during the start of the covid-19 pandemic.
            </p>
          </Col>
        </Row>
      </div>

      <hr className="project-separator" />

      <div className="project-section">
        <Row className="align-items-center">
          <Col md={6} className="project-image-col">
            <img
              src={`${process.env.PUBLIC_URL}/photos/Frontpage/soft-sorter.png`}
              alt="Soft Sorter"
              className="project-image"
            />
          </Col>
          <Col md={6} className="project-text-col">
            <h2 className="project-title">Soft Sorter</h2>
            <p className="project-description">
              For over thirty years pneumatic cylinder-paddle configurations are being used in food industries to automatically sort produce in high operating speeds. Despite their benefits, these multi-component assemblies are prone to faults, difficult to clean, and require frequent maintenance that halts the production lines for considerable amount of time. In this paper, we adopt the abundantly occurring spiral motions encountered in nature and translate them to the proof-of-concept designand development of a soft pneumatic actuator (SPA), the SoftER.
            </p>
            <p className="project-description">
            This novel actuator has the ability to rapidly unwind when pressurized to deliver impact forces. We explore this inherently low-cost and simple design and its potential to replace existing cylinder-paddle systems by strictly adhering to the requirements set by the industry. Simulation driven optimization methods are leveraged, utilizing quasi-static and dynamic finite element methods models, to create a scalable framework for selecting the best performing design parameters for each application. Using rapid manufacturing processes the optimized actuator is constructed and physical testing validates its high-speed and impact force delivering capabilities.
            </p>
          </Col>
        </Row>
      </div>

      <hr className="project-separator" />

      <div className="project-section">
        <Row className="align-items-center">
          <Col md={6} className="project-image-col">
            <img
              src={`${process.env.PUBLIC_URL}/photos/Frontpage/robotic-hand.png`}
              alt="Robotic Hand"
              className="project-image"
            />
          </Col>
          <Col md={6} className="project-text-col">
            <h2 className="project-title">Robotic Hand</h2>
            <p className="project-description">
              In the present thesis, the reconstruction, experimental evaluation, and kinematic analysis of the anthropomorphic robotic hand are studied. Initially a kinematic analysis is made of the movement of the human hand. The experimental evaluation of the anthropomorphic robotic hand in the use of an autonomous handle for grabbing objects is studied, with the use of some pre-handles. As well as various gestures, it has the ability to perform the robotic hand, obeying the commands it receives through the voice recognition system. The kinematic analysis of the robotic hand based on motion equations resulting from the use of computer vision is also studied. The equations of motion are approached by two methods, a linear relation, and a third-degree polynomial relation.
            </p>
          </Col>
        </Row>
      </div>
      
      {/* Add more projects as necessary */}
    </Container>
  );
}

export default Projects;
